import React from 'react';
import { Box, Heading, SimpleGrid, VStack, Text, useColorModeValue } from '@chakra-ui/react';

interface ModuleProps {
  title: string;
  features: string[];
  bg: string;
}

const Module: React.FC<ModuleProps> = ({ title, features, bg }) => (
  <Box bg={bg} p={4} borderRadius="md" height="100%">
    <VStack align="start" spacing={3}>
      <Heading as="h3" size="md" color="white">
        {title}
      </Heading>
      {features.map((feature, index) => (
        <Text key={index} color="white" fontSize="sm">
          ✦ {feature}
        </Text>
      ))}
    </VStack>
  </Box>
);

export const How: React.FC = () => {
  const bgColor = useColorModeValue('gray.100', 'gray.800');
  const darkBlue = useColorModeValue('blue.700', 'blue.900');
  const lightBlue = useColorModeValue('blue.500', 'blue.700');

  return (
    <Box as="section" py={16} bg={bgColor} width="100%">
      <Heading as="h2" size="2xl" mb={8} textAlign="center">
        How It Works
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={4} width="100%">
        <Module
          title="AI-Powered Integration Layer"
          features={[
            "APIs and custom connectors to seamlessly integrate with existing enterprise tools (e.g., Slack, Microsoft Teams, Jira, Salesforce)",
            "Employs machine learning algorithms to understand the context and relationships between different tools and data sources"
          ]}
          bg={darkBlue}
        />
        <Module
          title="Large Language Model Engine"
          features={[
            "Leverages state-of-the-art LLMs to process and generate human-like text across all communication channels",
            "Enables sophisticated understanding of context, nuance, & intent in all text-based interactions",
            "Generates coherent & contextually appropriate responses, summaries, & suggestions",
            "Facilitates multi-lingual support & cross-lingual information synthesis"
          ]}
          bg={lightBlue}
        />
        <Module
          title="Knowledge Graph Construction"
          features={[
            "Builds a comprehensive, dynamic knowledge graph of the organization's information ecosystem",
            "Maps relationships between projects, teams, documents, and individual contributions"
          ]}
          bg={darkBlue}
        />
        <Module
          title="Predictive Analytics Module"
          features={[
            "Applies machine learning models to historical data to forecast potential issues, bottlenecks, and opportunities",
            "Continuously learns from new data to improve accuracy over time"
          ]}
          bg={lightBlue}
        />
        <Module
          title="Collaboration Enhancement"
          features={[
            "Provides contextually relevant information and suggestions during meetings and collaborative sessions",
            "Automatically summarizes discussions and assigns action items",
            "Multi modal with text, images, audio and video"
          ]}
          bg={lightBlue}
        />
        <Module
          title="Workflow Optimization Engine"
          features={[
            "Analyzes existing workflows to identify inefficiencies and redundancies",
            "Suggests and implements process improvements based on best practices and organizational data"
          ]}
          bg={darkBlue}
        />
        <Module
          title="Personalized Insights Delivery"
          features={[
            "Tailors information and recommendations to each user's role, preferences, and current tasks",
            "Utilizes a sophisticated ranking algorithm to ensure the most relevant insights are prioritized"
          ]}
          bg={lightBlue}
        />
        <Module
          title="Adaptive Learning System"
          features={[
            "Continuously refines its understanding of the organization's unique culture and practices",
            "Allows for human feedback to improve accuracy and relevance of suggestions"
          ]}
          bg={darkBlue}
        />
      </SimpleGrid>
      <Box mt={8} p={4} bg={darkBlue} borderRadius="md" color="white" textAlign="center" width="100%">
        <SimpleGrid columns={4}>
          <Text fontWeight="bold">Secure</Text>
          <Text fontWeight="bold">Scalable</Text>
          <Text fontWeight="bold">Compliant</Text>
          <Text fontWeight="bold">Private</Text>
        </SimpleGrid>
      </Box>
    </Box>
  );
};
