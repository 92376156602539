import React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider } from '@chakra-ui/react';
import App from './App';
import { theme } from '@chakra-ui/pro-theme'
import { extendTheme } from '@chakra-ui/react'

const proTheme = extendTheme(theme)

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider>
      <App />
    </ChakraProvider>
  </React.StrictMode >,
  document.getElementById('root')
);
