import React from 'react';
import { Box, Button, Center, Heading, Text, VStack, useColorModeValue, Container } from '@chakra-ui/react'
import heroBackground from '../images/hero-background.png'

export const Hero = () => {
  return (
    <Box
      as="section"
      position="relative"
      h={{ base: '560px', md: '640px' }}
      bgImage={`url(${heroBackground})`}
      bgSize="cover"
      bgPosition="center"
      width="100%"
      _after={{
        content: `""`,
        display: 'block',
        w: 'full',
        h: 'full',
        bg: 'blackAlpha.600',
        position: 'absolute',
        inset: 0,
        zIndex: 0,
      }}
    >
      <Container maxW="container.xl" h="full" position="relative" zIndex={1}>
        <Center flexDirection="column" textAlign="center" color="white" h="full">
          <VStack spacing={4} w="full">
            <Heading size="2xl" fontWeight="extrabold">
              Empowering Collective Brilliance
            </Heading>
            <Text fontSize="xl" fontWeight="medium" maxW="2xl">
              YRI is the first collaborative AI co-pilot designed to transform teamwork into collective brilliance, revolutionizing the way teams connect and innovate.
            </Text>
          </VStack>
        </Center>
      </Container>
      <Box
        display={{ base: 'none', md: 'block' }}
        position="absolute"
        zIndex={2}
        w="full"
        bottom="0"
        py="4"
        bg="blackAlpha.400"
      >
        <Container maxW="container.xl">
          <Center color="white" fontWeight="medium">
            <Text fontSize="lg">Seamlessly integrating AI across teams to unlock your organization's full potential</Text>
          </Center>
        </Container>
      </Box>
    </Box>
  )
}
