import React from 'react';
import { Box, Heading, Text, SimpleGrid, VStack, useColorModeValue, Container } from '@chakra-ui/react';

interface FeatureProps {
  title: string;
  description: string;
}

const Feature: React.FC<FeatureProps> = ({ title, description }) => {
  return (
    <VStack align="start" spacing={3}>
      <Heading as="h3" size="md">
        {title}
      </Heading>
      <Text>{description}</Text>
    </VStack>
  );
};

export const Overview: React.FC = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.700');

  return (
    <Box as="section" py={16} bg={bgColor} width="100%">
      <Container maxW="container.xl" px={{ base: 4, sm: 6, lg: 8 }}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
          <Feature
            title="Empowering Collective Brilliance"
            description="YRI is the first collaborative AI co-pilot designed to empower organizations by transforming teamwork into collective brilliance. It's more than just a tool—YRI revolutionizes the way teams connect and innovate, unlocking the full potential of collaboration."
          />
          <Feature
            title="Seamless AI-Driven Collaboration"
            description="YRI transforms collaboration by seamlessly integrating AI across teams. By connecting minds and catalyzing innovation, it ensures that everyone in the organization works together effectively, fostering a new level of synergy."
          />
          <Feature
            title="Enterprise-Wide AI Integration"
            description="YRI offers an AI-powered collaborative environment that connects every aspect of the enterprise, from business to technology. This integration boosts productivity and catalyzes innovation, ensuring that every part of the organization works in harmony."
          />
          <Feature
            title="Bridging Organizational Connections"
            description="YRI's AI capabilities bridge connections across the entire organization, fostering a culture of collaboration. It drives innovation by breaking down silos and encouraging cross-functional teamwork, leading to groundbreaking results."
          />
        </SimpleGrid>
      </Container>
    </Box>
  );
};
