import React from 'react';
import { Box, Heading, Text, VStack, HStack, Container, Icon, useColorModeValue, Image } from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa';
import networkImage from '../images/network.png';

const FeatureItem: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <HStack align="start" spacing={4}>
    <Icon as={FaCheck} color="blue.400" w={5} h={5} mt={1} />
    <Text fontSize="lg">{children}</Text>
  </HStack>
);

export const What: React.FC = () => {
  const bgColor = useColorModeValue('gray.100', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'gray.100');

  return (
    <Box as="section" py={16} bg={bgColor} color={textColor} width="100%">
      <Container maxW="container.xl" px={{ base: 4, sm: 6, lg: 8 }}>
        <VStack spacing={8} align="stretch">
          <Heading as="h2" size="3xl" mb={4} textAlign={{ base: "center", lg: "left" }}>
            What is YRI?
          </Heading>
          <HStack spacing={8} align="start" flexDirection={{ base: 'column', lg: 'row' }}>
            <VStack align={{ base: "center", lg: "start" }} spacing={6} flex={1}>
              <Text fontSize="xl" fontWeight="bold" textAlign={{ base: "center", lg: "left" }}>
                YRI is an AI-Powered Collaborative Platform That:
              </Text>
              <VStack align="start" spacing={4} width="100%">
                <FeatureItem>
                  Seamlessly integrates with existing enterprise tools & systems
                </FeatureItem>
                <FeatureItem>
                  Analyzes & connects information across organizational silos
                </FeatureItem>
                <FeatureItem>
                  Provides real-time insights & recommendations to team members
                </FeatureItem>
                <FeatureItem>
                  Facilitates clear communication & alignment on project goals
                </FeatureItem>
                <FeatureItem>
                  Automates routine tasks & highlights potential issues before they become problems
                </FeatureItem>
                <FeatureItem>
                  Adapts to each organization's unique workflow & culture
                </FeatureItem>
              </VStack>
            </VStack>
            <Box flex={1} display={{ base: 'none', lg: 'block' }}>
              <Image
                src={networkImage}
                alt="Network Visualization"
                w="100%"
                h="400px"
                objectFit="cover"
                borderRadius="md"
              />
            </Box>
          </HStack>
        </VStack>
      </Container>
    </Box>
  );
};
