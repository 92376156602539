import React, { useEffect } from 'react';
import { Box, Heading, Flex, Image, useColorModeValue } from '@chakra-ui/react';
import bot_contact from '../images/contact_us.jpeg'

declare global {
  interface Window {
    hbspt?: any;
  }
}

export const Contact: React.FC = () => {
  const bgColor = useColorModeValue('blue.800', 'blue.900'); // Dark blue background

  useEffect(() => {
    // Load HubSpot script
    const script = document.createElement('script');
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.charset = "utf-8";
    script.type = "text/javascript";
    document.body.appendChild(script);

    script.onload = () => {
      // Create HubSpot form
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "47141829",
          formId: "7d1696d7-62cd-49b2-b7e1-34b815b19235",
          target: "#hubspot-form-container"
        });
      }
    };

    // Cleanup function to remove the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box as="section" py={16} bg={bgColor} id="contact-section" width="100%">
      <Box maxW="container.xl" mx="auto" p={8} borderRadius="md" bg={bgColor} boxShadow="lg">
        <Flex align="center" justify="center">
          <Box flex="1" pr={8} display={{ base: 'none', md: 'block' }}>
            {/* Placeholder Image */}
            <Image
              src={bot_contact}
              alt="Placeholder"
              borderRadius="md"
              boxShadow="lg"
            />
          </Box>
          <Box flex="1">
            <Heading as="h2" size="2xl" mb={8} textAlign="left" color="white">
              Connect with YRI
            </Heading>
            <Box id="hubspot-form-container" color="white" />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
