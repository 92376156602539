import React from 'react';
import { Box, Heading, Image, useColorModeValue } from '@chakra-ui/react';
import threads_png from '../images/threads.png';

export const Threads: React.FC = () => {
  const textColor = useColorModeValue('gray.200', 'white');

  return (
    <Box as="section" py={0} width="100%" height="500px" position="relative" overflow="hidden">
      <Image
        src={threads_png} // Replace with your image URL
        alt="AI Connecting Threads Visualization"
        objectFit="cover"
        width="100%"
        height="100%"
        position="absolute"
        top="0"
        left="0"
        zIndex="1"
      />
      {/* Overlay for better text visibility */}
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        bg="rgba(0, 0, 0, 0.5)" // Semi-transparent overlay
        zIndex="2"
      />
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        textAlign="center"
        zIndex="3"
        width="100%"
        px={4}
      >
        <Heading as="h2" size="2xl" color={textColor} textShadow="2px 2px 4px rgba(0, 0, 0, 0.6)">
          Connecting Threads & Unifying Teams
        </Heading>
      </Box>
    </Box>
  );
};
