import React from 'react';
import { Box, Heading, Text, VStack, Container, useColorModeValue } from '@chakra-ui/react';

export const Story: React.FC = () => {
  const bgColor = useColorModeValue('rgba(0, 0, 0, 0.7)', 'rgba(0, 0, 0, 0.8)');
  const textColor = useColorModeValue('white', 'gray.100');

  return (
    <Box
      as="section"
      py={16}
      position="relative"
      backgroundImage="url('/api/placeholder/1500/800')"  // Replace with actual image URL
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundAttachment="fixed"
      width="100%"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bg={bgColor}
        zIndex={0}
      />
      <Container maxW="container.xl" position="relative" zIndex={1} px={{ base: 4, sm: 6, lg: 8 }}>
        <VStack align={{ base: "center", md: "start" }} spacing={6} color={textColor}>
          <Heading as="h2" size="3xl" mb={4} textAlign={{ base: "center", md: "left" }}>
            Our Story
          </Heading>
          <Text fontSize="xl" textAlign={{ base: "center", md: "left" }}>
            YRI was born from our firsthand experience with inefficiencies
            and risk management in Fortune 500 companies and consulting.
            We saw how disconnected teams and information led to project
            failures, despite an abundance of tools.
          </Text>
          <Text fontSize="xl" fontWeight="bold" textAlign={{ base: "center", md: "left" }}>
            YRI is our solution: A Collaborative AI co-pilot that bridges
            these gaps, enhancing existing tools to unify teams, streamline
            communication, and boost productivity.
          </Text>
          <Text fontSize="xl" textAlign={{ base: "center", md: "left" }}>
            It's not just another tool, but a transformative force that aligns
            organizations for consistent project success and improved ROI.
          </Text>
        </VStack>
      </Container>
    </Box>
  );
};
