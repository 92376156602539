import React from 'react';
import { Box, Flex, Button, Image, useColorModeValue, Container } from '@chakra-ui/react';
import yri_logo from '../images/yri_logo_c.png'

export const Header: React.FC = () => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const buttonColorScheme = useColorModeValue('blue', 'teal');

  const scrollToContact = () => {
    const contactSection = document.getElementById('contact-section');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box as="header" position="fixed" top={0} left={0} right={0} zIndex={10} width="100%">
      <Box bg={bgColor} boxShadow="sm" width="100%">
        <Container maxW="container.xl" px={4}>
          <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            py="1.5rem"
            width="100%"
          >
            <Flex align="center">
              <Image
                src={yri_logo}
                alt="YRI Logo"
                width="165px"
                height="auto"
              />
            </Flex>
            <Box>
              <Button
                onClick={scrollToContact}
                colorScheme={buttonColorScheme}
                variant="solid"
              >
                Connnect
              </Button>
            </Box>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};
